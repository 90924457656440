<template>
    <div>
        <div class="row">
            <div class="col-sm-12 col-md-8">
                <div class="row">
                    <div v-for="(machine, index) in machines" class="col-xs-12 col-sm-6 col-md-4 mb-4" :key="index">
                        <div class="card">
                            <div class="card-header">
                                {{ machine.ip }}
                                <div class="status-light" :class="{'sl-green': machine.connected === true, 'sl-red': machine.connected !== true}"></div>
                            </div>
                            <div class="card-body">
                                <div>Os: {{ machine.os || 'N/A' }}</div>
                                <div>Cpu Usage: {{ machine.cpuUsage || 0 }}%</div>
                                <div>Total Memory: {{ (machine.autosense ? machine.autosense.memoryTotal * 1024 * 1024 : 0) | bandwidth }}</div>
                                <div>Available Memory: {{ (machine.autosense ? machine.autosense.memoryFree * 1024 * 1024 : 0) | bandwidth }}</div>

                                <div>Max Allowed Workers: {{ machine.autosense ? machine.autosense.allowed : 0 }}</div>
                                <div>Max Available Workers: {{ machine.autosense ? machine.autosense.available : 0 }}</div>
                                <div>Current Workers: {{ machine.workers || 0 }}</div>
                                <div>Process ID: {{ machine.pid || 'N/A' }}</div>
                                <div>Parent Process ID: {{ machine.ppid || 'N/A' }}</div>
                                <div>Status: {{ machine.connected ? machine.status : 'Unreachable' }}</div>
                                <div v-if="machine.info">
                                    <div v-if="machine.config">Config</div>
                                    <ul v-if="machine.config">
                                        <li>Source: {{ machine.config.source }}</li>
                                        <li>Verbose: {{ machine.config.verbose }}</li>
                                        <li>Average: {{ machine.config.average }}</li>
                                        <li>Reserved: {{ machine.config.reserved }}</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="card-footer text-right">
                                <div v-if="(machine.loading !== false && machine.status !== 'stopped') || machine.status === 'updating'">
                                    <img class="loader" src="/loader.gif">
                                </div>
                                <div v-else>
                                    <div v-if="machine.connected" title="Information" class="btn btn-sm ml-2" @click="machine.info = machine.info ? false : true"><i class="fa fa-info-circle" v-b-tooltip.top title="Information"></i></div>
                                    <div v-if="machine.connected && machine.status === 'stopped'" class="btn btn-sm ml-2" @click="activateDisableManager(machine)"><i class="fa fa-play" v-b-tooltip.top title="Start"></i></div>
                                    <div v-else-if="machine.connected && machine.status === 'active'" class="btn btn-sm ml-2" @click="activateDisableManager(machine)"><i class="fa fa-stop" v-b-tooltip.top title="Stop"></i></div>
                                    <div v-if="machine.status === 'active'" class="btn btn-sm ml-2" @click="restartManager(machine)"><i class="fa fa-repeat" v-b-tooltip.top title="Restart"></i></div>
                                    <div v-if="machine.connected" class="btn btn-sm ml-2" @click="openNav(machine)"><i class="fa fa-line-chart" v-b-tooltip.topright title="Open Navigation"></i></div>
                                    <div v-if="!machine.connected" class="btn btn-sm ml-2" @click="removeNode(machine)"><i class="fa fa-trash" v-b-tooltip.right title="Remove Node"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="sideBar" class="sidenav">
                    <a href="javascript:void(0)" class="closebtn" @click="closeNav()">&times;</a>
                    <div>
                        <div class="chart">
                            <line-chart :data="cpuUsage.data" area />
                        </div>

                        <div class="chart mt-4">
                            <line-chart :data="memUsage.data" area />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-body">
                            <button class="btn btn-primary btn-sm" @click="updateManager">Update Application</button>
                        </div>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-12">
                        <div class="card card-body">
                            <button class="btn btn-success btn-sm" style="margin-bottom: 10px" @click="addAlarm">Add Alarm</button>
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in alarm.list" :key="item._id">
                                        <td>{{ item. name }}</td>
                                        <td>
                                            <div class="btn-group btn-group-sm">
                                                <button type="button" class="btn btn-primary"><i class="fa fa-gears"></i></button>
                                                <button type="button" class="btn btn-primary dropdown-toggle dropdown-icon" data-toggle="dropdown">
                                                    <span class="sr-only">Toggle Dropdown</span>
                                                    <div class="dropdown-menu" role="menu">
                                                        <a class="dropdown-item" @click="editAlarm(item)"><span class="fa fa-pencil" title="Edit"></span> Edit</a>
                                                        <div class="dropdown-divider"></div>
                                                        <a class="dropdown-item" @click="deleteAlarm(item)"><span class="fa fa-trash" title="Delete"></span> Delete</a>
                                                    </div>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-modal ref="alarm-modal" v-model="alarm.modalShow" hide-footer :title="alarm.modalTitle">
            <div class="row mb-2">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h6>Alarm Properties</h6>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-sm-12 col-md-9">
                                    <div class="form-group">
                                        <label> Name of Alarm</label>
                                        <b-form-input v-model="alarm.item.name" placeholder=""></b-form-input>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3">
                                    <b-form-checkbox
                                        v-model="alarm.item.sendNotification"
                                        :value="true"
                                        :unchecked-value="false"
                                    >
                                        Send Notifications
                                    </b-form-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h6>Alarm Recipients</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <label for="tags-basic">Add recipient email addresses in the area provided below.  </label>
                                <b-form-tags placeholder="Add emails here....." input-id="tags-basic" v-model="alarm.item.notificationRecipients"></b-form-tags>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h6>Alarm Constraints</h6>
                        </div>
                        <div class="card-body">
                            <div class="form-group">
                                <label> Target Area </label>
                                <b-form-select v-model="alarm.item.constraint.item" :options="alarm.items"></b-form-select>
                            </div>
                            <div class="form-group">
                                <label> Action </label>
                                <b-form-select v-model="alarm.item.constraint.type" :options="alarm.types"></b-form-select>
                            </div>
                            <div class="form-group">
                                <label> Constraint </label>
                                <b-form-select v-model="alarm.item.constraint.equality" :options="alarm.equalities"></b-form-select>
                            </div>
                            <div class="form-group">
                                <label> Constraint Value </label>
                                <b-form-input v-model="alarm.item.constraint.value"></b-form-input>
                            </div>
                            <div class="form-group">
                                <label> Constraint Time Period </label>
                                <b-form-select v-model="alarm.item.constraint.periodDuration" :options="alarm.periods"></b-form-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-12">
                    <button class="btn btn-default" @click="alarm.modalShow = false">Close</button>
                    <button class="btn btn-primary" @click="save">Save</button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
    // import axios from 'axios'
    // import swal from 'sweetalert'
    import axios from "axios";
    import swal from "sweetalert";

    export default {
        name: 'Logs',
        data: function () {
            return {
                machines: [],
                cpuUsage: {
                    data: [
                        {
                            name: "CPU Utilization",
                            "x-name": "",
                            "y-name": "% Utilization",
                            data: []
                        }
                    ]
                },
                memUsage: {
                    data: [
                        {
                            name: "Memory Utilization",
                            "x-name": "",
                            "y-name": "% Utilization",
                            data: []
                        }
                    ]
                },
                alarm:{
                    url: `${this.$root.serverUrl}/admin/manager/alarm`,
                    modalTitle:"",
                    modalAction:"",
                    modalShow:false,
                    submitting:false,
                    items:[
                        { value: "manager", text: "Manager" },
                        { value: "worker", text: "Worker" },
                    ],
                    types:[
                        { value: "fingerprint", text: "Finger Print" },
                        { value: "total_memory", text: "Total Memory" },
                        { value: "free_memory", text: "Free Memory" },
                        { value: "maximum_allowed_workers", text: "Maximum Allowed Workers" },
                        { value: "maximum_available_workers", text: "Maximum Available Workers" },
                        { value: "current_worker", text: "Current Worker" },
                        { value: "internal_ip", text: "Internal IP" },
                        { value: "external IP", text: "External IP" },
                        { value: "pid", text: "PID" },
                        { value: "ppid", text: "PPID" },
                        { value: "command_line_arguments", text: "Command-Line Arguments" }
                    ],
                    equalities:[
                        { value:"=", text: "Equal To" },
                        { value:"!=", text: "Not Equal To" },
                        { value:"<", text: "Less Than" },
                        { value:"<=", text: "Less Than or Equal To" },
                        { value:">", text: "Greater Than" },
                        { value:"=>", text: "Greater Than or Equal To" },
                    ],
                    periods:[
                        { value: "sec", text: "Second(s)" },
                        { value: "min", text: "Minute(s)" },
                        { value: "hrs", text: "Hour(s)" },
                        { value: "dys", text: "Day(s)" },
                        { value: "wks", text: "Week(s)" },
                        { value: "mts", text: "Month(s)" }
                    ],
                    itemId:null,
                    item:{
                        name:"",
                        sendNotification:false,
                        notificationRecipients:[

                        ],
                        constraint:{
                            item:"",
                            type:"",
                            equality:"",
                            value:"",
                            periodDuration:""
                        }
                    },
                    list:[]
                }
            }
        },
        sockets: {
            'manager.disconnect': function (fingerprint) {
                this.machines.findIndex((el) => {
                    if (el.fingerprint === fingerprint) {
                        el.status = 'disabled';
                        el.connected = false;
                        el.loading = false;
                    }
                })
            },
            'manager.ping.res': function (data) {
                this.machines.findIndex((el) => {
                    if (el.fingerprint === data.fingerprint){
                        el = Object.assign(el, data)
                        if(el.status === 'starting') el.loading = true;
                        else el.loading = false;
                        el.connected = true;
                        if(!el.cpuStats) el.cpuStats = data.history.cpu;
                        let date = new Date(data.time);
                        el.cpuStats.push({label: `${date.getHours()}:${date.getMinutes()}`, value: data.cpuUsage});
                        if(!el.memStats) el.memStats = data.history.mem;
                        el.memStats.push({label: `${date.getHours()}:${date.getMinutes()}`, value: (100 - data.memUsage.freeMemPercentage)});
                        this.$forceUpdate()
                    }
                });
            }
        },
        destroyed: function() {
        },
        created: function(){
            this.search();
            this.listAlarms();
        },
        mounted: function(){
        },
        methods:{
            removeNode(machine) {
                this.$root.preloader = true;

                axios.delete(`${this.$root.serverUrl}/admin/nodes/${machine._id}`).then(() => {
                    this.$root.preloader = false;
                    swal({title:'Oops', text: `Update request submitted`, icon: 'success'})
                }).catch((err) => {
                    this.$root.preloader = false;
                    console.log('update.error', err)
                    swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${err.message}`, icon: 'error'})
                });
            },
            activateDisableManager(machine) {
                machine.loading = true;
                this.$socket.emit(`manager.${machine.status !== 'active' ? 'start' : 'stop'}`, machine);
                this.$forceUpdate()
            },
            restartManager(machine) {
                machine.loading = true;
                this.$socket.emit('manager.restart', machine);
                this.$forceUpdate()
            },
            updateManager() {
                var request = {'apikey': this.$root.apikey};
                this.$root.preloader = true;

                axios.post(`${this.$root.serverUrl}/admin/nodes/update`, request).then(() => {
                    this.$root.preloader = false;
                    swal({title:'Oops', text: `Update request submitted`, icon: 'success'})
                }).catch((err) => {
                    this.$root.preloader = false;
                    console.log('update.error', err)
                    swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${err.message}`, icon: 'error'})
                });
            },
            openNav(item) {
                this.cpuUsage.data[0].data = item.cpuStats;
                this.memUsage.data[0].data = item.memStats;
                document.getElementById("sideBar").style.width = "50%";
            },
            closeNav() {
                document.getElementById("sideBar").style.width = "0";
            },
            search: function(){
                var request = {'apikey': this.$root.apikey, sSearch:null, filters:{}};
                this.$root.preloader = true;

                axios.get(`${this.$root.serverUrl}/admin/nodes`, {params:request}).then(function(resp){
                    //Store the stats
                    this.machines = (resp.data) ? resp.data.data : [];

                    for(let machine in this.machines) {
                        this.machines[machine].loading = true;
                        this.machines[machine].status = "Unreachable";
                        setTimeout(()=> {
                            this.machines[machine].loading = false;
                            this.$forceUpdate()
                        }, 5000)
                    }

                    this.$root.preloader = false;
                }.bind(this));
            },
            addAlarm: function(){
                this.alarm.modalTitle = "Add Alarm";
                this.alarm.modalAction = "add";
                this.alarm.modalShow = true;
                this.alarm.itemId = null;
                this.alarm.item = {
                    name:"",
                    sendNotification:false,
                    notificationRecipients:[

                    ],
                    constraint:{
                        item:"",
                        type:"",
                        equality:"",
                        value:"",
                        periodDuration:""
                    }
                };
            },
            editAlarm: function(item){
                this.alarm.modalTitle = `Edit Alarm (${item.name})`;
                this.alarm.modalAction = 'edit';
                this.alarm.modalShow = true;
                this.alarm.itemId = item._id;
                this.alarm.item = item;
            },
            deleteAlarm: function(item){
                swal({title:"Remove", text:`Are you sure you want to remove the alarm '${item.name}'?`, icon:"info", buttons:['No','Yes']}).then(function(val){
                    if(!val) return

                    axios({url:`${this.alarm.url}/${item._id}`, data:{}, responseType:'json', method:'DELETE'}).then(function(){
                        swal("Success!","Alarm removed successfully", "success");
                        this.listAlarms();
                    }.bind(this))
                        .catch(function(err){
                            swal("Oops!",err.message,"error");
                        }.bind(this))
                }.bind(this))
            },
            save: function(){
                const method = (this.alarm.modalAction === 'add') ? "POST" : "PUT";
                const message = (this.alarm.modalAction === 'add') ? "Alarm added successfully" : "Alarm updated successfully";
                if(this.alarm.modalAction === 'add') delete this.alarm.item._id;
                if(this.alarm.modalAction === 'edit'){
                    delete this.alarm.item.created_ts;
                    delete this.alarm.item.modified_ts;
                    delete this.alarm.item.id;
                }
                const data = {};
                data.args = this.alarm.item;
                data.apiKey = this.$root.apikey;
                axios({url:`${this.alarm.url}`, data, responseType:'json', method }).then(function(){
                    swal("Success!",message, "success");
                    this.listAlarms();
                    this.alarm.modalShow = false;
                }.bind(this)).catch(function(err){
                    swal("Oops!",err.message,"error");
                }.bind(this));
            },
            listAlarms: function(){
                axios({url: this.alarm.url, data:{}, responseType: 'json', method: 'GET'}).then(function(resp){
                    this.alarm.list = resp.data.data.data;
                }.bind(this));
            }
        }
    }
</script>

<style>
    .loader {
        width: 30px;
        height: 30px;
    }
    .status-light {
        margin: 5px 10px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        float: right;
    }
    .sl-red {
        background-color: #940;
        box-shadow: #225 0 -1px 7px 1px, inset #600 0 -1px 9px, #F00 0px 0px 8px;
    }
    .sl-green {
        background-color: #690;
        box-shadow: #225 0 -1px 7px 1px, inset #460 0 -1px 9px, #7D0 0px 0px 8px;
    }
    .sidenav .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
    }

    .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 1;
        top: 0;
        right: 0;
        background-color: #fff;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 60px;
    }

    .chart {
        width: 100%;
        height: 300px;
    }

    @media screen and (max-height: 450px) {
        .sidenav {padding-top: 15px;}
        .sidenav a {font-size: 18px;}
    }
    .updatebtn {
        justify-content: flex-end;
    }
</style>
